import React, { useState } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Navbar from "../components/nav-bar"

const IndexPage = () => {
  const [showModal, setShowModal] = useState(false)

  function nextSlide() {
    let activeSlide = document.querySelector('.slide.translate-x-0');
    activeSlide.classList.remove('translate-x-0');
    activeSlide.classList.add('-translate-x-full');

    let nextSlide = activeSlide.nextElementSibling;
    nextSlide.classList.remove('translate-x-full');
    nextSlide.classList.add('translate-x-0');
  }

  function previousSlide() {
    let activeSlide = document.querySelector('.slide.translate-x-0');
    activeSlide.classList.remove('translate-x-0');
    activeSlide.classList.add('translate-x-full');

    let previousSlide = activeSlide.previousElementSibling;
    previousSlide.classList.remove('-translate-x-full');
    previousSlide.classList.add('translate-x-0');
  }

  return (

    // <meta http-equiv="Refresh" content="0; url='https://getsentinel.io'" />
    <html class="dark bg-darkbackg h-full">

      <SEO title="Obsidian - 2FA / MFA Authenticator" description='Your 2-Factor Authentication ally. On iCloud.' />

      <div class="bg-white dark:bg-darkbackg h-screen flex-col flex justify-start items-center mx-auto px-4, py-10">

        <a class="hidden sm:flex " style={{ height: 70 }} href="/">
          <img style={{ height: 70 }} class="h-8 w-auto" src={require('../assets/icons/obsidian-icon.png')} />
        </a>

        <h1 class="text-3xl tracking-tight font-bold text-gray-900 dark:text-white sm:text-2xl lg:text-2xl xl:text-3xl py-0 mt-5">
          <span class="font-sans block xl:inline">
            Obsidian Authenticator has been renamed "Sentinel"
          </span>
        </h1>

        <p class="font-sans mt-3 text-base sm:text-sm lg:text-base text-gray-300 sm:mt-5 sm:mx-auto md:mt-5 lg:mx-0 text-center">
          <span class="font-sans block xl:inline">
            For too many times we have been confused with the "Obsidian note-taking app".
            <br /> Same team, same purpose. We only thought we deserved our own identity.
          </span>
        </p>
        <a href="https://getsentinel.io">
          <div class="bg-white p-3 rounded">
            <p class="font-sans font-bold text-base sm:text-sm lg:text-base m-0 text-center"> Check out the new website </p>
          </div>
        </a>
        {/* <div class="pb-8 h-full sm:pb-16 md:pb-20 lg:w-1/2"> 
          

          <Navbar />

          <main class="flex-col flex justify-center mx-auto h-full px-4 pt-8 lg:pl-15 xl:pl-20">
            <div class="md:text-center lg:text-left lg:w-full">
              <h1 class="text-5xl tracking-tight font-extrabold text-gray-900 dark:text-white sm:text-3xl lg:text-4xl xl:text-5xl py-3">
                <span class="font-sans block xl:inline"> The Best Alternative to Google Authenticator and Authy! </span>
              </h1>
              <p class="font-sans mt-3 text-base sm:text-sm lg:text-base text-gray-300 sm:mt-5 sm:mx-auto md:mt-5 lg:mx-0">

                <b>Secured</b> on iCloud Keychain. <br />
                <b>Synced</b> between all your devices, so you'll <b>never</b> lose your tokens again.

                <br /><br />

                <div class='flex flex-row items-center justify-start'>
                  <img style={{ height: 25, margin: 0, padding: 0, marginRight: 10 }} class="" src={require('../images/applestore.png')} />
                  <img style={{ height: 25, margin: 0, padding: 0, marginRight: 10 }} class="" src={require('../images/45star-rating.gif')} />
                  <p class="font-sans text-sm text-gray-300">
                    4+ out of 5 worldwide
                  </p>
                </div>

              </p>
              <div class="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                <div class="rounded-md shadow">

                  <a target='_blank' href="https://apps.apple.com/us/app/obsidian-otp-authenticator/id1189922806" class="">
                    <img style={{ height: 60, margin: 0, padding: 0, marginRight: 10 }} class="" src={require('../images/appstore.svg')} />
                  </a>
                </div>
                <div class="mt-3 sm:mt-0 sm:ml-3">
                  <a target='_blank' href="https://apps.apple.com/us/app/obsidian-otp-authenticator/id1189922806" class="">
                    <img style={{ height: 60, margin: 0, padding: 0, marginRight: 10 }} class="" src={require('../images/macappstore.svg')} />
                  </a>
                </div>

              </div>
            </div>

          </main>
        </div>
        <div class="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 h-full">
         
          <img class="w-full object-contain sm:h-72 md:h-full lg:w-full lg:h-full" src={require("../assets/gif/out.gif")} alt="" />
        </div> */}

      </div>

      {/* <div class="w-full flex justify-center pt-5 sm:pt-0 bg-darkbackg">
        <img style={{ height: 25, margin: 0, padding: 0 }} class="" src={require('../assets/icons/arrowdown.png')} />
      </div> */}
      {/* 
      <div class=" relative w-screen h-80">
        <div class="absolute  inset-0 w-full h-80 bg-pink-500 text-white flex items-center justify-center text-5xl transition-all ease-in-out duration-1000 transform translate-x-0 slide">Hello <button onClick={() => nextSlide()}>Click</button></div>
        <div class="invisible absolute inset-0 w-full h-80 bg-purple-500 text-white flex items-center justify-center text-5xl transition-all ease-in-out duration-1000 transform translate-x-full slide">There</div>
        <div class="invisible absolute inset-0 w-full h-80 bg-teal-500 text-white flex items-center justify-center text-5xl transition-all ease-in-out duration-1000 transform translate-x-full slide">Booya!</div> */}
      {/* <div class=" bottom-0 right-0 bg-white w-16 h-16 flex items-center justify-center text-black">&#x276F;</div>
    <div class=" bottom-0 right-0 bg-white w-16 h-16 mr-16 border-r border-gray-400 flex items-center justify-center text-black">&#x276E;</div> */}
      {/* </div> */}
      {/* 
      <div class="pt-12 bg-darkbg">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div class="lg:text-center">
            <p class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-white font-sans sm:text-3xl">
              Why Obsidian?
            </p>
          </div>

          <div class="mt-10">
            <dl class="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
              <div class="flex">
                <div class="flex-shrink-0">
                  <div class="flex items-center justify-center h-12 w-12 rounded-md bg-primary text-white">

                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-shield" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M8 14.933a.615.615 0 0 0 .1-.025c.076-.023.174-.061.294-.118.24-.113.547-.29.893-.533a10.726 10.726 0 0 0 2.287-2.233c1.527-1.997 2.807-5.031 2.253-9.188a.48.48 0 0 0-.328-.39c-.651-.213-1.75-.56-2.837-.855C9.552 1.29 8.531 1.067 8 1.067v13.866zM5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 0 1 1.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.775 11.775 0 0 1-2.517 2.453 7.159 7.159 0 0 1-1.048.625c-.28.132-.581.24-.829.24s-.548-.108-.829-.24a7.158 7.158 0 0 1-1.048-.625 11.777 11.777 0 0 1-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692A1.54 1.54 0 0 1 2.185 1.43 62.456 62.456 0 0 1 5.072.56z" />
                    </svg>
                  </div>
                </div>
                <div class="ml-4">
                  <dt class="text-xl leading-6 font-extrabold text-white font-sans">
                    Secure
                  </dt>
                  <dd class="mt-2 text-base sm:text-sm text-gray-300 font-sans md:w-10/12">
                    Obsidian uses the iCloud Keychain to operate securely. Your data are never transferred or stored in any other external server.
                  </dd>
                </div>
              </div>

              <div class="flex">
                <div class="flex-shrink-0">
                  <div class="flex items-center justify-center h-12 w-12 rounded-md bg-primary text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-globe2" viewBox="0 0 16 16">
                      <path d="M1.5 1a.5.5 0 0 0-.5.5v3a.5.5 0 0 1-1 0v-3A1.5 1.5 0 0 1 1.5 0h3a.5.5 0 0 1 0 1h-3zM11 .5a.5.5 0 0 1 .5-.5h3A1.5 1.5 0 0 1 16 1.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 1-.5-.5zM.5 11a.5.5 0 0 1 .5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 1 0 1h-3A1.5 1.5 0 0 1 0 14.5v-3a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v3a1.5 1.5 0 0 1-1.5 1.5h-3a.5.5 0 0 1 0-1h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 1 .5-.5z" />
                      <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm8-9a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                    </svg>

                  </div>
                </div>
                <div class="ml-4">
                  <dt class="text-xl leading-6 font-extrabold text-white font-sans">
                    Privacy-aware
                  </dt>
                  <dd class="mt-2 text-base sm:text-sm text-gray-300 font-sans md:w-10/12">
                    Obsidian does not collect data nor require a sign-up to function.
                  </dd>
                </div>
              </div>

              <div class="flex">
                <div class="flex-shrink-0">
                  <div class="flex items-center justify-center h-12 w-12 rounded-md bg-primary text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-arrow-left-right" viewBox="0 0 16 16">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z" />
                    </svg>
                  </div>
                </div>
                <div class="ml-4">
                  <dt class="text-xl leading-6 font-extrabold text-white font-sans">
                    Seamless Syncing
                  </dt>
                  <dd class="mt-2 text-base sm:text-sm text-gray-300 font-sans md:w-10/12">
                    Obsidian syncs your data between your iOS and macOS via iCloud Keychain.
                  </dd>
                </div>
              </div>

              <div class="flex">
                <div class="flex-shrink-0">
                  <div class="flex items-center justify-center h-12 w-12 rounded-md bg-primary text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-palette" viewBox="0 0 16 16">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm4 3a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zM5.5 7a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm.5 6a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 8c0 3.15-1.866 2.585-3.567 2.07C11.42 9.763 10.465 9.473 10 10c-.603.683-.475 1.819-.351 2.92C9.826 14.495 9.996 16 8 16a8 8 0 1 1 8-8zm-8 7c.611 0 .654-.171.655-.176.078-.146.124-.464.07-1.119-.014-.168-.037-.37-.061-.591-.052-.464-.112-1.005-.118-1.462-.01-.707.083-1.61.704-2.314.369-.417.845-.578 1.272-.618.404-.038.812.026 1.16.104.343.077.702.186 1.025.284l.028.008c.346.105.658.199.953.266.653.148.904.083.991.024C14.717 9.38 15 9.161 15 8a7 7 0 1 0-7 7z" />
                    </svg>
                  </div>
                </div>
                <div class="ml-4">
                  <dt class="text-xl leading-6 font-extrabold text-white font-sans">
                    Beautiful Design
                  </dt>
                  <dd class="mt-2 text-base sm:text-sm text-gray-300 font-sans md:w-10/12">
                    Customize Obsidian to catch your eye with multiple themes and visual modes.
                  </dd>
                </div>
              </div>
            </dl>
          </div>
        </div>
      </div> */}




      {/* <div class="dark:bg-darkbackg max-w-7xl pt-16 mx-auto px-4 sm:px-6 lg:px-8 flex flex-col sm:items-center sm:justify-center">

        <div class="lg:text-center">
          <p class="text-3xl sm:pb-0 font-extrabold tracking-tight text-white font-sans sm:text-3xl">
            Compare with competitors
          </p>
        </div>
ì
        <div class="overflow-auto">
          <table class="divide-y divide-gray-200">
            <tr>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">

              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500">
                <div class="flex flex-col items-center justify-center">
                  <img style={{ height: 45, margin: 0, padding: 0 }} class="" src={require('../assets/icons/obsidian-icon.png')} />
                  <p class='text-base sm:text-sm text-gray-300 font-sans m-0'>Obsidian</p>
                </div>
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500">
                <div class="flex flex-col items-center justify-center">
                  <img style={{ height: 45, margin: 0, padding: 0 }} class="" src={require('../images/authy.png')} />
                  <p class='text-base sm:text-sm text-gray-300 font-sans m-0'>Authy</p>
                </div>
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500">
                <div class="flex flex-col items-center justify-center">
                  <img style={{ height: 45, margin: 0, padding: 0 }} class="" src={require('../images/googleauthenticator.png')} />
                  <p class='text-base sm:text-sm text-gray-300 font-sans m-0'>Google</p>
                </div>
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500">
                <div class="flex flex-col items-center justify-center">
                  <img style={{ height: 45, margin: 0, padding: 0 }} class="" src={require('../images/microsoft-auth.svg')} />
                  <p class='text-base sm:text-sm text-gray-300 font-sans m-0'>Microsoft</p>
                </div>
              </th>
            </tr>
            <tbody class="">
              <tr>
                <td class="p-0 whitespace-nowrap">
                  <p class='text-base sm:text-sm font-bold text-gray-300 font-sans m-0'>Use iCloud <br />Keychain</p>
                </td>
                <td class="">
                  <div class="flex flex-col items-center justify-center">
                    <img style={{ height: 17, margin: 0, padding: 0 }} class="" src={require('../images/tick.png')} />
                  </div>
                </td>
                <td class="">
                </td>
                <td class="">
                </td>
              </tr>

              <tr>
                <td class="p-0 whitespace-nowrap ">
                  <p class='text-base sm:text-sm font-bold text-gray-300 font-sans m-0'>macOS app</p>
                </td>
                <td class="">
                  <div class="flex flex-col items-center justify-center">
                    <img style={{ height: 17, margin: 0, padding: 0 }} class="" src={require('../images/tick.png')} />
                  </div>
                </td>
                <td class="">
                  <div class="flex flex-col items-center justify-center">
                    <img style={{ height: 17, margin: 0, padding: 0 }} class="" src={require('../images/tick.png')} />
                  </div>
                </td>
                <td class="">
                </td>
              </tr>

              <tr>
                <td class="p-0 whitespace-nowrap ">
                  <p class='text-base sm:text-sm font-bold text-gray-300 font-sans m-0'>Sync between <br />devices</p>
                </td>
                <td class="">
                  <div class="flex flex-col items-center justify-center">
                    <img style={{ height: 17, margin: 0, padding: 0 }} class="" src={require('../images/tick.png')} />
                  </div>
                </td>
                <td class="">
                  <div class="flex flex-col items-center justify-center">
                    <img style={{ height: 17, margin: 0, padding: 0 }} class="" src={require('../images/tick.png')} />
                  </div>
                </td>
                <td class="">
                </td>
                <td class="">
                  <div class="flex flex-col items-center justify-center">
                    <img style={{ height: 17, margin: 0, padding: 0 }} class="" src={require('../images/tick.png')} />
                  </div>
                </td>
              </tr>

              <tr>
                <td class="p-0 whitespace-nowrap">
                  <p class='text-base sm:text-sm font-bold text-gray-300 font-sans m-0'>Privacy aware</p>
                </td>
                <td class="">
                  <div class="flex flex-col items-center justify-center">
                    <img style={{ height: 17, margin: 0, padding: 0 }} class="" src={require('../images/tick.png')} />
                  </div>
                </td>
                <td class="">
                </td>
                <td class="">
                  <div class="flex flex-col items-center justify-center">
                    <img style={{ height: 17, margin: 0, padding: 0 }} class="" src={require('../images/tick.png')} />
                  </div>
                </td>
              </tr>

              <tr>
                <td class="p-0 whitespace-nowrap">
                  <p class='text-base sm:text-sm font-bold text-gray-300 font-sans m-0'>Backups</p>
                </td>
                <td class="">
                  <div class="flex flex-col items-center justify-center">
                    <img style={{ height: 17, margin: 0, padding: 0 }} class="" src={require('../images/tick.png')} />
                  </div>
                </td>
                <td class="">
                  <div class="flex flex-col items-center justify-center">
                    <img style={{ height: 17, margin: 0, padding: 0 }} class="" src={require('../images/tick.png')} />
                  </div>
                </td>

                <td class="">
                </td>
                <td class="">
                  <div class="flex flex-col items-center justify-center">
                    <img style={{ height: 17, margin: 0, padding: 0 }} class="" src={require('../images/tick.png')} />
                  </div>
                </td>
              </tr>

              <tr>
                <td class="p-0 whitespace-nowrap">
                  <p class='text-base sm:text-sm font-bold text-gray-300 font-sans m-0'>Dark Mode</p>
                </td>
                <td class="">
                  <div class="flex flex-col items-center justify-center">
                    <img style={{ height: 17, margin: 0, padding: 0 }} class="" src={require('../images/tick.png')} />
                  </div>
                </td>
                <td class="">
                </td>

                <td class="">
                  <div class="flex flex-col items-center justify-center">
                    <img style={{ height: 17, margin: 0, padding: 0 }} class="" src={require('../images/tick.png')} />
                  </div>
                </td>
              </tr>

              <tr>
                <td class="p-0 whitespace-nowrap">
                  <p class='text-base sm:text-sm font-bold text-gray-300 font-sans m-0'>Colored Themes</p>
                </td>
                <td class="">
                  <div class="flex flex-col items-center justify-center">
                    <img style={{ height: 17, margin: 0, padding: 0 }} class="" src={require('../images/tick.png')} />
                  </div>
                </td>
                <td class="">
                </td>

                <td class="">
                </td>
              </tr>

              <tr>
                <td class="p-0 whitespace-nowrap">
                  <p class='text-base sm:text-sm font-bold text-gray-300 font-sans m-0'>Folders</p>
                </td>
                <td class="">
                  <div class="flex flex-col items-center justify-center">
                    <img style={{ height: 17, margin: 0, padding: 0 }} class="" src={require('../images/tick.png')} />
                  </div>
                </td>
                <td class="">
                </td>

                <td class="">
                </td>
              </tr>

            </tbody>
          </table>
        </div>
      </div> */}
      {/* </div>
        </div>
      </div> */}





      {/* <div class="dark:bg-darkbackg max-w-7xl pt-16 mx-auto px-4 sm:px-6 lg:px-8 flex flex-col items-center justify-center">

        <div class="lg:text-center">
          <p class="text-3xl sm:pb-0 font-extrabold tracking-tight text-white font-sans sm:text-3xl">
            We care about your Privacy
          </p>
        </div>

        <div class="dark:bg-darkbackg w-full mx-auto sm:px-6 md:px-6 flex-row justify-center flex-grow flex flex-wrap">
          <img style={{ height: 100 }} class="" src={require('../images/privacy.png')} />
        </div>

        <dd class="mt-3 text-base text-gray-300 font-sans md:w-8/12 text-left sm:text-center">
          <b>Privacy is one of our core principles</b>. <br />
          Obsidian <b>does not</b> collect any data <b>nor stores or transfers</b> your tokens anywhere outside of your iCloud Keychain. <br />

        </dd>
      </div> */}



      {/* <div class="dark:bg-darkbackg">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">

          <div class="lg:text-center">
            <p class="text-3xl pt-16 font-extrabold tracking-tight text-white font-sans sm:text-3xl">
              Our Testimonials
            </p>
          </div>



          <div class="mt-10">
            <dl class="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">


              <div class="flex">
                <div class="flex-shrink-0">
                  <div class="flex items-center justify-center h-12 w-12 rounded-md bg-primary text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-chat-square-text" viewBox="0 0 16 16">
                      <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1h-2.5a2 2 0 0 0-1.6.8L8 14.333 6.1 11.8a2 2 0 0 0-1.6-.8H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h2.5a1 1 0 0 1 .8.4l1.9 2.533a1 1 0 0 0 1.6 0l1.9-2.533a1 1 0 0 1 .8-.4H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                      <path d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6zm0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z" />
                    </svg>
                  </div>
                </div>
                <div class="ml-4">
                  <dt class="text-lg leading-6 font-bold text-white font-sans">
                    Very happy with transition to Obsidian <p style={{ color: '#f8ca4f', padding: 0, margin: 0 }}>★★★★★</p>
                  </dt>
                  <dd class="mt-1 text-sm text-gray-300 font-sans">
                    SanderTr
                  </dd>
                  <dd class="mt-1 text-sm text-gray-300 font-sans md:w-10/12">
                    Went from Tofu to Obsidian! I was happy with Tofu but it had no syncing between devices. Obsidian does this securely with keychain! Interface is easy to use. Happy with my transition! Also icons are quickly added to its looks good too!

                  </dd>

                </div>
              </div>

              <div class="flex">
                <div class="flex-shrink-0">
                  <div class="flex items-center justify-center h-12 w-12 rounded-md bg-primary text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-chat-square-text" viewBox="0 0 16 16">
                      <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1h-2.5a2 2 0 0 0-1.6.8L8 14.333 6.1 11.8a2 2 0 0 0-1.6-.8H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h2.5a1 1 0 0 1 .8.4l1.9 2.533a1 1 0 0 0 1.6 0l1.9-2.533a1 1 0 0 1 .8-.4H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                      <path d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6zm0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z" />
                    </svg>
                  </div>
                </div>
                <div class="ml-4">
                  <dt class="text-lg leading-6 font-bold text-white font-sans">
                    Updated with new features all the time <p style={{ color: '#f8ca4f', padding: 0, margin: 0 }}>★★★★★</p>
                  </dt>
                  <dd class="mt-1 text-sm text-gray-300 font-sans">
                    /Equilibrium
                  </dd>
                  <dd class="mt-1 text-sm text-gray-300 font-sans md:w-10/12">
                    This is the best authenticator app. Works on all my devices, is fast to use, automatic backups, and great support.

                  </dd>

                </div>
              </div>


              <div class="flex">
                <div class="flex-shrink-0">
                  <div class="flex items-center justify-center h-12 w-12 rounded-md bg-primary text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-chat-square-text" viewBox="0 0 16 16">
                      <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1h-2.5a2 2 0 0 0-1.6.8L8 14.333 6.1 11.8a2 2 0 0 0-1.6-.8H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h2.5a1 1 0 0 1 .8.4l1.9 2.533a1 1 0 0 0 1.6 0l1.9-2.533a1 1 0 0 1 .8-.4H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                      <path d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6zm0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z" />
                    </svg>
                  </div>
                </div>
                <div class="ml-4">
                  <dt class="text-lg leading-6 font-bold text-white font-sans">
                    The easiest 2FA app to use <p style={{ color: '#f8ca4f', padding: 0, margin: 0 }}>★★★★★</p>
                  </dt>
                  <dd class="mt-1 text-sm text-gray-300 font-sans">
                    kiamlibr
                  </dd>
                  <dd class="mt-1 text-base sm:text-sm text-gray-300 font-sans md:w-10/12">
                    This app "just works". Zero configuration and it already synchronizes through all of my devices. It makes 2FA so easy to use that I've been enabling this feature in much more services than I did before, resulting in more security for me for no added hassle.
                  </dd>

                </div>
              </div>

              <div class="flex">
                <div class="flex-shrink-0">
                  <div class="flex items-center justify-center h-12 w-12 rounded-md bg-primary text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-chat-square-text" viewBox="0 0 16 16">
                      <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1h-2.5a2 2 0 0 0-1.6.8L8 14.333 6.1 11.8a2 2 0 0 0-1.6-.8H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h2.5a1 1 0 0 1 .8.4l1.9 2.533a1 1 0 0 0 1.6 0l1.9-2.533a1 1 0 0 1 .8-.4H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                      <path d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6zm0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z" />
                    </svg>
                  </div>
                </div>
                <div class="ml-4">
                  <dt class="text-lg leading-6 font-bold text-white font-sans">
                    Best MFA app ever - as it has a companion for MacOS <p style={{ color: '#f8ca4f', padding: 0, margin: 0 }}>★★★★★</p>
                  </dt>
                  <dd class="mt-2 text-sm text-gray-300 font-sans">
                    Sonnenschein987
                  </dd>
                  <dd class="mt-2 text-base sm:text-sm text-gray-300 font-sans md:w-10/12">
                    I am using this app for the last 2+ years. Coming from Google Authenticator the key benefit is the amazing companion app on MacOS. I need my tokens mostly on my Mac so having an easy copy and paste made my life so much better. The iOS app is straight forward and simple to use and always gives me quick access with copy and paste on my phone. Also love that it stores the tokens in iCloud, so when you get a new phone your tokens are still there. thank you!
                  </dd>

                </div>
              </div>

              <div class="flex">
                <div class="flex-shrink-0">
                  <div class="flex items-center justify-center h-12 w-12 rounded-md bg-primary text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-chat-square-text" viewBox="0 0 16 16">
                      <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1h-2.5a2 2 0 0 0-1.6.8L8 14.333 6.1 11.8a2 2 0 0 0-1.6-.8H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h2.5a1 1 0 0 1 .8.4l1.9 2.533a1 1 0 0 0 1.6 0l1.9-2.533a1 1 0 0 1 .8-.4H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                      <path d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6zm0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z" />
                    </svg>
                  </div>
                </div>
                <div class="ml-4">
                  <dt class="text-lg leading-6 font-bold text-white font-sans">
                    Best way to MFA! <p style={{ color: '#f8ca4f', padding: 0, margin: 0 }}>★★★★★</p>
                  </dt>
                  <dd class="mt-2 text-sm text-gray-300 font-sans">
                    Gump4487245
                  </dd>
                  <dd class="mt-2 text-base sm:text-sm text-gray-300 font-sans md:w-10/12">
                    Obsidian is better and more complete a solution for MFA than anything else I've tried. Other authentication systems lock up the keys in their own vault. Obsidian allows you to synchronize this vault across your protected Apple devices. So, my iPhone, iPad and MacBook Pro all have the same information and I can use which ever is handy to authenticate.
                  </dd>

                </div>
              </div>

              <div class="flex">
                <div class="flex-shrink-0">
                  <div class="flex items-center justify-center h-12 w-12 rounded-md bg-primary text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-chat-square-text" viewBox="0 0 16 16">
                      <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1h-2.5a2 2 0 0 0-1.6.8L8 14.333 6.1 11.8a2 2 0 0 0-1.6-.8H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h2.5a1 1 0 0 1 .8.4l1.9 2.533a1 1 0 0 0 1.6 0l1.9-2.533a1 1 0 0 1 .8-.4H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                      <path d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6zm0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z" />
                    </svg>
                  </div>
                </div>
                <div class="ml-4">
                  <dt class="text-lg leading-6 font-bold text-white font-sans">
                    App worth using in depth <p style={{ color: '#f8ca4f', padding: 0, margin: 0 }}>★★★★★</p>
                  </dt>
                  <dd class="mt-2 text-sm text-gray-300 font-sans">
                    Jialinapple
                  </dd>
                  <dd class="mt-2 text-base sm:text-sm text-gray-300 font-sans md:w-10/12">
                    What a surprise. It's available for iOS. It's a great experience on Mac. It's definitely a learning tool after syncing. I hope the developer will keep up the good work. Definitely support
                  </dd>

                </div>
              </div>

              <div class="flex">
                <div class="flex-shrink-0">
                  <div class="flex items-center justify-center h-12 w-12 rounded-md bg-primary text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-chat-square-text" viewBox="0 0 16 16">
                      <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1h-2.5a2 2 0 0 0-1.6.8L8 14.333 6.1 11.8a2 2 0 0 0-1.6-.8H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h2.5a1 1 0 0 1 .8.4l1.9 2.533a1 1 0 0 0 1.6 0l1.9-2.533a1 1 0 0 1 .8-.4H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                      <path d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6zm0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z" />
                    </svg>
                  </div>
                </div>
                <div class="ml-4">
                  <dt class="text-lg leading-6 font-bold text-white font-sans">
                    Simple, Fast, Beautiful <p style={{ color: '#f8ca4f', padding: 0, margin: 0 }}>★★★★★</p>
                  </dt>
                  <dd class="mt-2 text-sm text-gray-300 font-sans">
                    Fabio Poloni
                  </dd>
                  <dd class="mt-2 text-base sm:text-sm text-gray-300 font-sans md:w-10/12">
                    Obsidian is the perfect solution to store TOTP/HOTP tokens and sync them using iCloud. It's fast and reliable.
                  </dd>

                </div>
              </div>


              <div class="flex">
                <div class="flex-shrink-0">
                  <div class="flex items-center justify-center h-12 w-12 rounded-md bg-primary text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-chat-square-text" viewBox="0 0 16 16">
                      <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1h-2.5a2 2 0 0 0-1.6.8L8 14.333 6.1 11.8a2 2 0 0 0-1.6-.8H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h2.5a1 1 0 0 1 .8.4l1.9 2.533a1 1 0 0 0 1.6 0l1.9-2.533a1 1 0 0 1 .8-.4H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                      <path d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6zm0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z" />
                    </svg>
                  </div>
                </div>
                <div class="ml-4">
                  <dt class="text-lg leading-6 font-bold text-white font-sans">
                    Was looking for just that. <p style={{ color: '#f8ca4f', padding: 0, margin: 0 }}>★★★★★</p>
                  </dt>
                  <dd class="mt-2 text-sm text-gray-300 font-sans">
                    pprokhor
                  </dd>
                  <dd class="mt-2 text-base sm:text-sm text-gray-300 font-sans md:w-10/12">
                    I use 2FA where I can and it was always a pain to open Google Auth of the phone if you are trying to login on the computer. I was looking for an app that would have same tokens on both iPhone and Mac... I've tried half a dozen of them until I found Obsidian, that does exactly what I was looking for — OTP sync across all devices!
                  </dd>

                </div>
              </div>


              <div class="flex">
                <div class="flex-shrink-0">
                  <div class="flex items-center justify-center h-12 w-12 rounded-md bg-primary text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-chat-square-text" viewBox="0 0 16 16">
                      <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1h-2.5a2 2 0 0 0-1.6.8L8 14.333 6.1 11.8a2 2 0 0 0-1.6-.8H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h2.5a1 1 0 0 1 .8.4l1.9 2.533a1 1 0 0 0 1.6 0l1.9-2.533a1 1 0 0 1 .8-.4H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                      <path d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6zm0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z" />
                    </svg>
                  </div>
                </div>
                <div class="ml-4">
                  <dt class="text-lg leading-6 font-bold text-white font-sans">
                    Excellent! Finally! <p style={{ color: '#f8ca4f', padding: 0, margin: 0 }}>★★★★★</p>
                  </dt>
                  <dd class="mt-1 text-sm text-gray-300 font-sans">
                    Gump4487245
                  </dd>
                  <dd class="mt-1 text-sm text-gray-300 font-sans md:w-10/12">
                    Finally I can use MFA tokens in a whereever I am. Don't have my phone? Not a problem, it's on my Mac. How about if I just have my phone or iPad? Yes, it works there too. And yes, you can click(copy) / paste into your web applications. So smart. I use this all day long.
                  </dd>

                </div>
              </div>




              <div class="flex">
                <div class="flex-shrink-0">
                  <div class="flex items-center justify-center h-12 w-12 rounded-md bg-primary text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-chat-square-text" viewBox="0 0 16 16">
                      <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1h-2.5a2 2 0 0 0-1.6.8L8 14.333 6.1 11.8a2 2 0 0 0-1.6-.8H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h2.5a1 1 0 0 1 .8.4l1.9 2.533a1 1 0 0 0 1.6 0l1.9-2.533a1 1 0 0 1 .8-.4H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                      <path d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6zm0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z" />
                    </svg>
                  </div>
                </div>
                <div class="ml-4">
                  <dt class="text-lg leading-6 font-bold text-white font-sans">
                    Solid <p style={{ color: '#f8ca4f', padding: 0, margin: 0 }}>★★★★★</p>
                  </dt>
                  <dd class="mt-1 text-sm text-gray-300 font-sans">
                    Mark Rinella
                  </dd>
                  <dd class="mt-1 text-sm text-gray-300 font-sans md:w-10/12">
                    Clean and simple. Uses iCloud to sync across devices. Responsive dev who cares about his app.
                  </dd>

                </div>
              </div>




              <div class="flex">
                <div class="flex-shrink-0">
                  <div class="flex items-center justify-center h-12 w-12 rounded-md bg-primary text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-chat-square-text" viewBox="0 0 16 16">
                      <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1h-2.5a2 2 0 0 0-1.6.8L8 14.333 6.1 11.8a2 2 0 0 0-1.6-.8H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h2.5a1 1 0 0 1 .8.4l1.9 2.533a1 1 0 0 0 1.6 0l1.9-2.533a1 1 0 0 1 .8-.4H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                      <path d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6zm0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z" />
                    </svg>
                  </div>
                </div>
                <div class="ml-4">
                  <dt class="text-lg leading-6 font-bold text-white font-sans">
                    Great app and developer is even better <p style={{ color: '#f8ca4f', padding: 0, margin: 0 }}>★★★★★</p>
                  </dt>
                  <dd class="mt-1 text-sm text-gray-300 font-sans">
                    NoMoreBurdens
                  </dd>
                  <dd class="mt-1 text-sm text-gray-300 font-sans md:w-10/12">
                    This app is great. It has mostly everything you need. If it doesn’t have a feature you like, the developer is very open for suggestions. If you notice a bug, the developer is super quick to fix them if you let him know. I used to use the LastPass Authenticator. And it was rarely updated and it was uploaded externally. I recommend this app to anyone and it is well worth its money for its Premium version.

                  </dd>

                </div>
              </div>




              <div class="flex">
                <div class="flex-shrink-0">
                  <div class="flex items-center justify-center h-12 w-12 rounded-md bg-primary text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-chat-square-text" viewBox="0 0 16 16">
                      <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1h-2.5a2 2 0 0 0-1.6.8L8 14.333 6.1 11.8a2 2 0 0 0-1.6-.8H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h2.5a1 1 0 0 1 .8.4l1.9 2.533a1 1 0 0 0 1.6 0l1.9-2.533a1 1 0 0 1 .8-.4H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                      <path d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6zm0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z" />
                    </svg>
                  </div>
                </div>
                <div class="ml-4">
                  <dt class="text-lg leading-6 font-bold text-white font-sans">
                    Best OTP app I have found <p style={{ color: '#f8ca4f', padding: 0, margin: 0 }}>★★★★★</p>
                  </dt>
                  <dd class="mt-1 text-sm text-gray-300 font-sans">
                    IanInAus
                  </dd>
                  <dd class="mt-1 text-sm text-gray-300 font-sans md:w-10/12">
                    It just works and syncs perfectly across all my devices

                  </dd>

                </div>
              </div>



              <div class="flex">
                <div class="flex-shrink-0">
                  <div class="flex items-center justify-center h-12 w-12 rounded-md bg-primary text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-chat-square-text" viewBox="0 0 16 16">
                      <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1h-2.5a2 2 0 0 0-1.6.8L8 14.333 6.1 11.8a2 2 0 0 0-1.6-.8H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h2.5a1 1 0 0 1 .8.4l1.9 2.533a1 1 0 0 0 1.6 0l1.9-2.533a1 1 0 0 1 .8-.4H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                      <path d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6zm0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z" />
                    </svg>
                  </div>
                </div>
                <div class="ml-4">
                  <dt class="text-lg leading-6 font-bold text-white font-sans">
                    THE Authenticator app worth paying for! <p style={{ color: '#f8ca4f', padding: 0, margin: 0 }}>★★★★★</p>
                  </dt>
                  <dd class="mt-1 text-sm text-gray-300 font-sans">
                    @fellow
                  </dd>
                  <dd class="mt-1 text-sm text-gray-300 font-sans md:w-10/12">
                    I've used Google's free Authenticator App for years to store my 2FA tokens. Over the years I've had to change phones a couple of times and in doing so I've experienced duplicate token entries appearing for no reason and thus I've always lived in fear of 2FA tokens corrupting or deleting entirely – being locked out of any vital accounts I need to operate my business and finances would be an utter nightmare and the amount of time and hassle it would cost me makes it completely worth me paying/investing to remove the risk entirely:

                    Obsidian runs on iOS and macOS and sync's my 2FA tokens across multiple devices and has backups too (secured with apple iCloud and keychain). The one-off 'Fusion Lifetime' in-app purchase is a no-brainer given how effective it is and the QR code import feature makes it ridiculously easy to move everything over from Google in an instant.

                    The developer seems extremely friendly and responsive to questions and updates too! 5 Stars – 100% Recommend.

                  </dd>

                </div>
              </div>


              <div class="flex">
                <div class="flex-shrink-0">
                  <div class="flex items-center justify-center h-12 w-12 rounded-md bg-primary text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-chat-square-text" viewBox="0 0 16 16">
                      <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1h-2.5a2 2 0 0 0-1.6.8L8 14.333 6.1 11.8a2 2 0 0 0-1.6-.8H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h2.5a1 1 0 0 1 .8.4l1.9 2.533a1 1 0 0 0 1.6 0l1.9-2.533a1 1 0 0 1 .8-.4H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                      <path d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6zm0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z" />
                    </svg>
                  </div>
                </div>
                <div class="ml-4">
                  <dt class="text-lg leading-6 font-bold text-white font-sans">
                    The Authenticator you need <p style={{ color: '#f8ca4f', padding: 0, margin: 0 }}>★★★★★</p>
                  </dt>
                  <dd class="mt-1 text-sm text-gray-300 font-sans">
                    dZiTao
                  </dd>
                  <dd class="mt-1 text-sm text-gray-300 font-sans md:w-10/12">
                    This is the best 2FA Authenticator out there. It syncs seamlessly across devices so you’ll always have access to what you need. The developer is very responsive. This app gets my highest recommendation!

                  </dd>

                </div>
              </div>

            </dl>
          </div>
        </div>
      </div> */}





    </html>
  )
}

export default IndexPage
